import React from "react"

import Page from "../../modules/NewLandingPage/components/Page"

const SiteTermos = () => {
  return (
    <Page>
      <div className="container termos">
        <div className="head-lp">
          <div className="pre-lp">levamos sua privacidade a sério</div>
          <h1>Termos e condições de uso</h1>
        </div>
        <p>
          <strong>Pagamentos com renovação automática</strong>
        </p>
        <p>
          Ao adicionar um cartão de crédito para assinar o conteúdo do
          Anonimatta, você concorda em iniciar uma assinatura com renovação
          automática, e que o pagamento mensal, trimestral, semestral ou anual
          não é reembolsável. Cobraremos o valor do plano selecionado de forma
          recorrente no cartão de crédito. Você pode cancelar quando quiser e o
          cancelamento entrará em vigor no final do período de cobrança.
        </p>
        <p>
          <strong>O Anonimatta</strong>
        </p>
        <p>
          O Anonimatta é a solução definitiva para ajudar fotógrafos e modelos a
          monetizarem seus trabalhos de forma justa e livre de censura. Nossa
          plataforma possui um extenso catalogo com mais de 200 ensaios,
          criadores de conteúdo ativos e um acervo com mais de 4000 mídias. O
          acesso ao conteúdo é um serviço exclusivo para assinantes, que podem
          adquirir planos para acessar o conteúdo do Anonimatta ou conteúdos
          exclusivos dos Criadores. Nossa sede física fica na Cidade de
          Uberlândia/MG, na Rua Marcelo Barbosa, nº 128 – Bairro Shopping Park –
          CEP 38425-552. Estamos inscritos no CNPJ com o n.º 27.476.350/0001-85.
        </p>
        <p>
          <strong>Um pequeno glossário</strong>
        </p>
        <p>
          <strong>1. Conteúdo Anonimatta:</strong> Todo o conteúdo produzido
          pela equipe Anonimatta (fotos e vídeos de modelos fotográficas), para
          o seu entretenimento aqui na plataforma.
        </p>
        <p>
          <strong>2. Criador de Conteúdo:</strong> É o usuário que envia
          qualquer tipo de material para a plataforma, com o objetivo de receber
          acesso de outros usuários por meio da venda de uma assinatura mensal.
        </p>
        <p>
          <strong>3. Assinante:</strong> Todo usuário que paga pelo serviço, por
          meio de assinatura, para visualizar tanto o conteúdo produzido pelo
          Anonimatta, quanto o produzido pelo Criador de Conteúdo.
        </p>
        <p>
          <strong>4. Afiliado:</strong> Funciona como um “promotor”. O Afiliado
          recebe uma comissão de 30% (trinta por cento), por cada nova
          assinatura indicada e concretizada. Ah, essa comissão acontece apenas
          e exclusivamente após o primeiro pagamento do assinante, não há
          comissão referente a recorrências de pagamentos.
        </p>
        <p>
          <strong>O que o Anonimatta oferece?</strong>
        </p>
        <p>
          Fotos e vídeos sem censura, serviço de mídia social e também a
          possibilidade do usuário se tornar um Criador. Criadores podem definir
          preços em troca de criar e compartilhar conteúdos de sua autoria em
          seus perfis privados no Anonimatta. A monetização dos criadores pode
          ser gerada através de seguidores/amigos de suas redes sociais ou com
          acessos de assinantes do Anonimatta.
        </p>
        <p>
          <strong>Quem pode ser usuário do Anonimatta?</strong>
        </p>
        <p>
          Todo mundo que tiver mais que 18 anos, número de telefone próprio e
          for aprovado pela nossa política de aprovação.
        </p>
        <p>
          <strong>Onde e como você paga pelo serviço do Anonimatta?</strong>
        </p>
        <p>
          Para acessar nosso conteúdo, você precisa contratar um plano de
          assinatura usando um cartão de crédito ou PIX. Todos os pagamentos
          realizados no Anonimatta são processados pela MOIP by Pagseguro e
          Gerencianet.
        </p>
        <p>
          <strong>
            Legal, quero fazer parte do Anonimatta. Quais serão minhas
            obrigações?
          </strong>
        </p>
        <p>
          <strong>1. Pagar pelo serviço!</strong> A sua principal obrigação com
          a gente é efetuar o pagamento da sua assinatura em dia.
        </p>
        <p>
          <strong>2. Seus dados:</strong> Para utilizar nossa plataforma,
          precisamos que você informe alguns dados pessoais no momento do
          cadastro. Esses dados são importantíssimos e, por isso, precisam ser
          verdadeiros e estar sempre atualizados. Então não esqueça: trocou de
          celular ou teve algum outro dado alterado? Avisa a gente!
        </p>
        <p>
          <strong>3. Não pode emprestar seu CPF</strong> nem usar o CPF de outra
          pessoa para assinar o Anonimatta, beleza?
        </p>
        <p>
          <strong>4. Conferir, por favor.</strong> Lembre-se de conferir todos
          os dados antes de confirmar sua assinatura - é seu direito e sua
          responsabilidade. Todos os dados da transação estarão descritos na
          página “minha conta” en nosso site. E, já sabe, se encontrar alguma
          coisa errada, entre em contato com a gente!
        </p>
        <p>
          <strong>5. Propriedade intelectual:</strong> todos os materiais,
          fotografias, vídeos, softwares, marcas, tecnologias, nomes e programas
          veiculados pelo Anonimatta são protegidos por direitos autorais, sendo
          de propriedade do Anonimatta. Qualquer violação a esses direitos pelo
          assinante ou por terceiro utilizando-se de sua senha será de
          responsabilidade do assinante, implicando a adoção das medidas legais
          aplicáveis e na imediata terminação da assinatura. Respeite nosso
          trabalho =).
        </p>
        <p>
          <strong>6. Para sua diversão particular:</strong> Todos os conteúdos
          publicados nos sites do Anonimatta são direcionados para uso
          particular. Todo e qualquer material publicado nos sites são
          protegidos por direitos autorais, marca registrada e todas as leis de
          propriedade intelectual aplicáveis e pertencem ou são controlados pelo
          Anonimatta.
        </p>
        <p>
          <strong>
            E se eu mudar de ideia? Posso cancelar minha conta no Anonimatta? E
            se eu quiser cancelar apenas uma assinatura?
          </strong>
        </p>
        <p>
          <strong>1. Com certeza.</strong> Aqui só fica quem quer. Para
          cancelar, basta acessar a página “minha conta” e selecionar a opção
          “cancelar assinatura”. Para deletar seu perfil da plataforma, envie um
          email para: suporte@anonimatta.com.br
        </p>
        <p>
          <strong>Agora me diz o que eu não posso fazer no Anonimatta?</strong>
        </p>
        <p>
          <strong>1. Não utilize um CPF que não seja o seu:</strong> Já falamos
          sobre isso, mas é importante reforçar. Se você emprestar seu CPF ou
          usar o CPF de outra pessoa, sua assinatura poderá ser reprovada.
        </p>
        <p>
          <strong>2. Atividade ilegal:</strong> Isso é meio óbvio, mas vamos
          deixar claro; você não pode utilizar o Anonimatta para qualquer
          finalidade que viole o direito de terceiros. Além do que a lei define
          como proibido, o Anonimatta considera ilegal: qualquer situação que
          envolva menores de 18 anos; conteúdo obsceno; difamatório; que
          propague ódio de qualquer tipo; assediante e; qualquer tipo de
          violência (física ou moral) Ficaremos de olho!
        </p>
        <p>
          <strong>3. Roubo de conteúdo:</strong> você não pode copiar,
          distribuir, modificar qualquer conteúdo do Anonimatta. Quer utilizar
          alguma coisa? Fale com a gente pelo suporte@anonimatta.com.br.
        </p>
        <p>
          <strong>4. Conferir, por favor.</strong> Lembre-se de conferir todos
          os dados antes de confirmar sua assinatura - é seu direito e sua
          responsabilidade. Todos os dados da transação estarão descritos na sua
          área logada no nosso site. E, já sabe, se encontrar alguma coisa
          errada, entre em contato com a gente!
        </p>
        <p>
          <strong>5. Serviços que não permitimos:</strong> Não é permitido:
          divulgar ou oferecer serviços de acompanhantes; divulgar ou oferecer
          fogos de artifício, armas de fogo, drogas ou qualquer instrumento que
          favoreça a utilização destes; divulgar ou oferecer serviços de
          terceiros, como concursos, sorteios ou qualquer outra promoção não
          autorizada, de forma expressa, pelo Anonimatta. Lembrando que sua
          assinatura para uso pessoal, então, nada de emprestar sua conta para o
          amiguinho, hem!
        </p>
        <p>
          <strong>6. Violação dos termos e condições de uso:</strong> Todo mundo
          comete deslizes, nós sabemos disso. Caso isso aconteça, você será
          notificado por Whatsapp. Se não for a primeira vez, você pode ter seu
          acesso desativado. Se você tiver informações de algum conteúdo que não
          deveria estar aqui, entre em contato imediatamente por
          suporte@anonimatta.com.br. Quero ser um criador de conteúdo no
          Anonimatta. E agora?
        </p>
        <p>
          <strong>1. Liberdade:</strong> Após publicar o conteúdo, você autoriza
          qualquer assinante a acessar e visualizar o seu material
          disponibilizado. Ah, você também assume possuir direitos sobre todo o
          material disponibilizado e caso contrário, responderá por isso.
        </p>
        <p>
          <strong>2. Autorização:</strong> Você concede, de forma automática,
          direitos sobre; uso, reprodução, modificação, execução, exibição,
          distribuição ou qualquer outra forma de divulgação do material
          disponibilizado, ao Anonimatta e qualquer parceiro licenciado, bem
          como qualquer sucessor. Só para ficar claro: O Anonimatta nunca irá
          vender seu conteúdo para qualquer outra plataforma. Pode ficar
          tranquilo.
        </p>
        <p>
          <strong>3. Você será responsável!</strong> Deverá indenizar o
          Anonimatta ou qualquer parceiro licenciado, bem como qualquer
          sucessor; no caso de prejuízos causados pelo material publicado e
          divulgado ilegalmente. O valor da indenização será calculado de acordo
          ao dano causado, seja ele reputacional ou não.
        </p>
        <p></p>
        <strong>4. Grana na mão.</strong> O Anonimatta pagará uma comissão de
        80% (oitenta por cento) aos criadores de conteúdo por todas as vendas
        que forem feitas através do Anonimatta. Será retida uma taxa de
        administração de 20% (vinte por cento) dos valores comercializados por
        meio do site. Essa taxa de administração é para manter e operar o site.
        <p>
          <strong>5. Tem alguma dúvida?</strong> Caso você ainda não se sinta
          seguro ou tenha dúvidas sobre ser um criador de conteúdo, envie um
          email para suporte@anonimatta.com.br, teremos todo prazer em ajudar.
        </p>
        <p>
          <strong>Finalmentes</strong>
        </p>
        <p>
          <strong>1. O mundo muda o tempo todo:</strong> De tempos em tempos
          poderemos entender que o conteúdo destes termos e condições precisará
          ser revisto para continuar adequado aos nossos serviços. Aí vamos
          alterar o que for necessário e caso você não concorde com o que
          alterarmos, tá tudo bem, em até 10 dias da alteração dos termos no
          nosso site, você pode pedir, por qualquer um dos nossos canais de
          atendimento, para mantermos os termos que você já concordou para uma
          assinatura específica, até o vencimento desta mensalidade.
        </p>
        <p>
          <strong>2. Utilização de links e #Hashtags:</strong> Você pode criar
          links em suas mídias sociais ou qualquer outra ferramenta para levar
          até o site do Anonimatta, mas deve fazer de uma forma bacana, sem
          prejudicar o site, beleza? Você também poderá utilizar a #anonimatta
          nas redes sociais e nos autoriza a compartilhar o conteúdo marcado em
          sua rede social pública ou privada.
        </p>
        <p>
          <strong>3. Licença de uso:</strong> O Anonimatta não vende conteúdo.
          Oferecemos uma licença para utilização do site. Essa licença tem
          limitações e restrições, podendo ser revogada a qualquer tempo. Tem
          alguma dúvida sobre a licença fornecida? Entre em contato pelo
          endereço eletrônico suporte@anonimatta.com.br.
        </p>
        <p>
          <strong>4. É direto do Anonimatta, não abrimos mão:</strong> Excluir
          qualquer conteúdo postado em nosso site, se não estiver em
          conformidade com estes termos ou com qualquer lei brasileira. Também é
          nosso direito desativar qualquer nome de usuário, senha ou outro
          identificador, de acordo com o mesmo critério estabelecido. O
          Anonimatta é uma empresa que colabora com a justiça, sempre que
          solicitado enviará as informações necessárias.
        </p>
        <p>
          <strong>5. Cada um tem a sua:</strong> Nenhum conteúdo publicado por
          terceiros reflete a opinião do Anonimatta. Por isso, não nos
          responsabilizamos por qualquer terceiro pelo conteúdo publicado em
          nosso site.
        </p>
        <p>
          <strong>6. Cuide de sua conta:</strong> A responsabilidade por toda e
          qualquer atividade que ocorrer na conta é sua, portanto, proteja suas
          informações de acesso ao site. Caso você descubra que alguém acessou
          sua conta, entre em contato imediatamente pelo endereço eletrônico
          suporte@anonimatta.com.br. Ah, não se esqueça de fazer o “logout”
          sempre que não foi mais utilizar o site.
        </p>
        <p>
          <strong>7. Fora do ar:</strong> A gente vai sempre fazer o máximo pra
          que tudo ocorra bem, mas pode ser que nossos serviços fiquem
          temporariamente indisponíveis em situações que fujam do nosso
          controle. Se isso acontecer e você precisar da nossa ajuda, lembre-se
          que sempre poderá entrar em contato com o nosso atendimento. Estamos
          aqui o tempo todo.
        </p>
        <p>
          <strong>8. Responsabilidade fiscal:</strong> Tanto o Criador de
          Conteúdo quanto o Afiliado autorizam, desde já, o Anonimatta descontar
          das comissões os valores referentes ao pagamento de impostou e/ou
          tributos, relacionados ao pagamento das mesmas.
        </p>
        <p>
          <strong>9. Ainda ficou com alguma dúvida?</strong> Acesse aqui nosso
          https://anonimatta.com.br/ajuda/ ou entre em contato com a gente por
          qualquer um dos canais de atendimento disponíveis no nosso site! Para
          sua própria segurança, estes contatos serão gravados por nós.
        </p>
        <p>
          <strong>
            AO CLICAR EM LI E ACEITO, VOCÊ CONCORDA COM TODOS OS TERMOS
            PROPOSTOS NESTE DOCUMENTO.
          </strong>
        </p>
      </div>
    </Page>
  )
}

export default SiteTermos
